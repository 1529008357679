<template>
  <add-edit-property-component :is-edit="false" />
</template>
<script>

// eslint-disable-next-line import/extensions
import AddEditPropertyComponent from '@/views/pages/properties/AddEditPropertyComponent'

export default {
  name: 'AddNewProperty',
  components: { AddEditPropertyComponent },
}
</script>

<style>
  #fileInput {
    visibility: hidden;
  }
</style>
